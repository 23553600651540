:root {
    --main_color: #b3e5fc;
    --text-color: #ffffff
}

html {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    height: 100%;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 0;
    background-color: #f0f0f0;
    max-width: 450px;
    width: 100%;
}

#app {
    display: flex;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    min-height: 100%;
}

.invisible {
    display: none;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

.header, .footer {
    max-width: 450px;
}

.header {
    background-color: #6CC8C3;
    position: fixed;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;
    padding: 20px 35px;
}

.logo {
    cursor: pointer;
    height: 25px;
}

.menu {
    cursor: pointer;
    height: 25px;
}

.container {
    margin: 65px 0;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.footer {
    width: 100%;
    height: 65px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6CC8C3;
    color: #fff;
    padding: 20px 45px;
    font-size: 16px;
}

.submit-button {
    margin-left: 25px;
    margin-right: 25px;
    background-color: #fff;
    color: #29A39C;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding: 8px 15px;
    border-radius: 10px;
    text-decoration: none;
}

.delete-button {
    margin-left: 25px;
    margin-right: 25px;
    background-color: #fff;
    color: #CA5767;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding: 8px 15px;
    border-radius: 10px;
    text-decoration: none;
}

/* New */
@font-face {
    font-family: 'kurland';
    src: url('./kurland.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* NAVIGATION START */
.footer {
    width: 100%;
    height: 65px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6CC8C3;
    color: #fff;
    padding: 20px 45px;
    font-size: 16px;
}

.navigation-link {
    display: flex;
    background-color: #6CC8C3;
    color: #ffffff;
    width: 100%;
    height: 65px;
    padding: 20px 35px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    border-radius: 15px;
}
/* NAVIGATION END */

/* CARD START */





.card {
    background-color: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #252525;

}

.card img {
    width: 100%;
}

.card .logo-img-card {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px 20px 0 0;
}

.card .logo-img {
    width: 120px;
}

.card-info {
    padding: 15px 15px 10px 15px;
}

.card h1 {
    margin: 0;
    font-family: 'kurland', sans-serif; 
    color: #29A39C;
    font-weight: 700;
    font-size: 18px;
}

.card h2 {
    margin: 0;
    font-family: 'kurland', sans-serif; 
    color: #29A39C;
    font-size: 12px;

}

.card h3 {
    margin-top: 5px;
    font-size: 10px;
}

.card h4 {
    color: #3D3D3D;
    font-size: 12px;
    margin-right: 10px;
    color: #29A39C;
}

.card h5 {
    font-size: 12px;
}

.card-info-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-info-footer button { 
    padding: 10px 100px; 
    background-color: #29A39C;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
}

.card-info-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #252525;
}

.card-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.card-counter-count {
    font-weight: 700;
    display: flex;
    width: 50px;
    align-items: center;
    justify-content: center;
}

.card-counter-button {
    width: 50px;
    height: 30px;
    background-color: #fff;
    color: #29A39C;
    box-shadow: 0 4px 8px #0000001a, 0 1px 2px #0003;
    border-radius: 5px;
    font-size: 22px;
    padding: 0;
    font-weight: 700;
}

.card-form-content {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.2);
}

.card-price {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    color: #29a39c;
}

.card-delivery {
    display: flex;
    justify-content: space-between;
    gap: 15px
}

.card-delivery-type {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 8px;
    border-radius: 5px;
    background-color: rgba(108, 200, 195, 0.2);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.card-delivery-type img {
    height: 30px;
}

.card-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 10px;
}

.card-form-input {
    height: 33px;
    padding-left: 10px;
    border-radius: 8px;
    color: #3D3D3D;
    border: 1px solid #29A39C;
}

.card-error {
    color: #CA5767;
    font-size: 14px;
    padding-left: 15px;
}

.fiscal-button {
    height: 30px;
    color: #fff;
    background-color: #6cc8c3;
}

/* CARD END */
