:root {
  --main_color: #b3e5fc;
  --text-color: #fff;
}

html {
  box-sizing: border-box;
  justify-content: center;
  height: 100%;
  display: flex;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: #f0f0f0;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 0;
  font-family: Arial, sans-serif;
}

#app {
  justify-content: center;
  width: 100%;
  max-width: 450px;
  min-height: 100%;
  display: flex;
}

.invisible {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

.header, .footer {
  max-width: 450px;
}

.header {
  background-color: #6cc8c3;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  padding: 20px 35px;
  display: flex;
  position: fixed;
}

.logo, .menu {
  cursor: pointer;
  height: 25px;
}

.container {
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 65px 0;
  padding: 20px;
  display: flex;
}

.submit-button {
  color: #29a39c;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 25px;
  margin-right: 25px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

.delete-button {
  color: #ca5767;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 25px;
  margin-right: 25px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

@font-face {
  font-family: kurland;
  src: url("kurland.6a223601.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.footer {
  color: #fff;
  background-color: #6cc8c3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 20px 45px;
  font-size: 16px;
  display: flex;
  position: fixed;
  bottom: 0;
}

.navigation-link {
  color: #fff;
  background-color: #6cc8c3;
  border-radius: 15px;
  width: 100%;
  height: 65px;
  padding: 20px 35px;
  text-decoration: none;
  display: flex;
  box-shadow: 0 4px 8px #0000001a, 0 1px 2px #0003;
}

.card {
  cursor: pointer;
  color: #252525;
  background-color: #fff;
  border-radius: 15px;
  flex-direction: column;
  text-decoration: none;
  transition: box-shadow .3s;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 8px #0000001a, 0 1px 2px #0003;
}

.card img {
  width: 100%;
}

.card .logo-img-card {
  justify-content: end;
  align-items: center;
  padding: 20px 20px 0 0;
  display: flex;
}

.card .logo-img {
  width: 120px;
}

.card-info {
  padding: 15px 15px 10px;
}

.card h1 {
  color: #29a39c;
  margin: 0;
  font-family: kurland, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.card h2 {
  color: #29a39c;
  margin: 0;
  font-family: kurland, sans-serif;
  font-size: 12px;
}

.card h3 {
  margin-top: 5px;
  font-size: 10px;
}

.card h4 {
  color: #29a39c;
  margin-right: 10px;
  font-size: 12px;
}

.card h5 {
  font-size: 12px;
}

.card-info-footer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card-info-footer button {
  color: #fff;
  background-color: #29a39c;
  border-radius: 5px;
  padding: 10px 100px;
  font-size: 12px;
}

.card-info-content {
  color: #252525;
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.card-counter {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.card-counter-count {
  justify-content: center;
  align-items: center;
  width: 50px;
  font-weight: 700;
  display: flex;
}

.card-counter-button {
  color: #29a39c;
  background-color: #fff;
  border-radius: 5px;
  width: 50px;
  height: 30px;
  padding: 0;
  font-size: 22px;
  font-weight: 700;
  box-shadow: 0 4px 8px #0000001a, 0 1px 2px #0003;
}

.card-form-content {
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px #0000001a, 0 1px 2px #0003;
}

.card-price {
  color: #29a39c;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  display: flex;
}

.card-delivery {
  justify-content: space-between;
  gap: 15px;
  display: flex;
}

.card-delivery-type {
  cursor: pointer;
  background-color: #6cc8c333;
  border-radius: 5px;
  flex: 1;
  justify-content: center;
  padding: 8px;
  transition: background-color .3s;
  display: flex;
}

.card-delivery-type img {
  height: 30px;
}

.card-form {
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
  display: flex;
}

.card-form-input {
  color: #3d3d3d;
  border: 1px solid #29a39c;
  border-radius: 8px;
  height: 33px;
  padding-left: 10px;
}

.card-error {
  color: #ca5767;
  padding-left: 15px;
  font-size: 14px;
}

.fiscal-button {
  color: #fff;
  background-color: #6cc8c3;
  height: 30px;
}
/*# sourceMappingURL=index.8f6853b4.css.map */
